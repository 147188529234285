<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="deleteSuccess" class="alert alert-success" role="alert">
      大会が削除されました。
    </div>
    <div v-if="deleteFailed" class="alert alert-danger" role="alert">
      トーナメントは削除されていません。
    </div>
    <!-- 12-1 -->
    <div v-if="!addTournamentScreen && !addNewBulkTournamentScreen && !batchRegistrationTournamentConfirmationScreen
                    && !batchRegistrationTournamentSuccessMsgScreen && !tournamentsListScreen">
      <div class="row">
        <div class="col-2 filtering-column140">
          <label for="filter-select">ステータス</label>
          <select v-model="tournamentStatus" class="form-control mt-1 filter-select form-select"
                  @change="getData">
            <option v-for="tournamentStatus in tournamentStatuses" :value="tournamentStatus.id">
              {{ tournamentStatus.name }}
            </option>
          </select>
        </div>
        <div class="col-2 date-range filtering-column270">
          <label for="filter-search">大会期間</label>
          <DatePicker ref="datePicker" :position="'right'" class="mt-1" v-bind:date-range="dateRange"
                      @change-date="updateData"></DatePicker>
        </div>
        <div class="col-4 filtering-column270">
          <label for="keyword">キーワード</label>
          <div class="input-group mt-1">
            <input id="keyword" v-model="keyword"
                   class="form-control py-2 bg-transparent filter-search-form"
                   placeholder="キーワードを入力"
                   type="search" @keydown.enter="onKeyDown">
            <span class="input-group-append">
              <button class="input-group-text bg-transparent p-2 border-left-radius-0" type="submit" @click="search"><i
                  class="fa fa-search"></i></button>
            </span>
            <div v-show="errors['keyword']" class="form-error">{{ errors['keyword'] }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="width-170">
          <button class="btn custom-button-outline fw-bold d-flex justify-content-around p-l-0"
                  @click="addBulkTournament">
            <img src="../assets/images/ic_collective registration.svg">一括新規登録
          </button>
        </div>
        <div class="width-170 float-right">
          <button class="btn custom-button fw-bold p-l-1" @click="addTournament"><i
              class="fas fa-plus float-start"></i>新規登録
          </button>
        </div>
      </div>
      <div class="datatable">
        <Datatable
            :columns="columns"
            :getData="getData"
            :loading="loading"
            :response="response"
            :sortable="true"
            @cta="cta">
        </Datatable>
      </div>
    </div>

    <!-- 12-7 -->
    <div v-else-if="tournamentsListScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToTournament">大会管理</a><i
            class="fas fa-angle-right form-control-color"></i> 大会情報</p>
        <div class="card p-5 text-first">
          <div class="row d-flex justify-content-between">
            <div class="col-6">
              <i class="fas fa-angle-left"></i><a class="text-decoration-underline cursor-pointer mx-1"
                                                  @click="prevTournament">
              {{ prevTournamentName }}{{ prevTournamentId === null ? '' : '(' + prevTournamentId + ')' }}</a>
            </div>
            <div class="col-6 text-end">
              <a class="text-decoration-underline cursor-pointer" @click="nextTournament">{{
                  nextTournamentName
                }}{{ nextTournamentId === null ? '' : '(' + nextTournamentId + ')' }}</a><i
                class="fas fa-angle-right mx-1"></i>
            </div>
          </div>
          <div class="card-body mx-auto">
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">大会名</label>
                <input v-model="jtaTournament.tournamentName" class="form-control"
                       type="text">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label for="exampleInputPassword1">大会開始日</label>
                <input v-model="jtaTournament.startAt" class="form-control half-width-input mt-1"
                       type="text">
              </div>
              <div class="col-6">
                <label for="exampleInputPassword1">大会終了日</label>
                <input v-model="jtaTournament.endAt" class="form-control half-width-input mt-1"
                       type="text">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>開催都道府県</label>
                <select v-model="jtaTournament.hostPrefectureId"
                        class="form-control filter-select-long form-select mt-1">
                  <option
                      v-for="(prefecture, prefectureId) in prefectures"
                      :key="prefectureId"
                      :value="prefecture.prefectureId"
                  >
                    {{ prefecture.prefectureName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">主催団体</label>
                <input v-model="jtaTournament.hostOrganization" class="form-control" type="text">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">お問い合わせ電話番号</label>
                <input v-model="jtaTournament.inquiryPhoneNo" class="form-control"
                       type="email">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">お問い合わせメール</label>
                <input v-model="jtaTournament.inquiryEmail" class="form-control"
                       type="email">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">大会管理者<span class="text-888888">（複数名の場合はセミコロン「;」で区切って入力してください）</span></label>
                <input v-model="jtaTournament.tournamentAdminEmail" class="form-control"
                       type="email">
              </div>
            </div>
            <div class="form-check ml-2 mt-4 mx-auto">
              <input id="flexCheckDefault" v-model="jtaTournament.pastAnswersRequired"
                     class="form-check-input m-t-5"
                     type="checkbox" value="">
              <label class="form-check-label" for="flexCheckDefault">過去14日間における回答をさせる
              </label>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col-4 mt-4">
                <Modal :back-to-root="backToTournament"
                       :data="jtaTournament"
                       :reload-data="getData"
                       :tournament-delete="deleteTournament"
                       body=" を削除してもよろしいですか？" button-title="削除" cancel-title="キャンセル"
                       ok-title="削除" title="削除"></Modal>
              </div>
              <div class="col-4">
                <button class="btn cancel-min font-weight-bold mx-auto mt-4" @click="backToTournament">
                  キャンセル
                </button>
              </div>
              <div class="col-4">
                <button class="btn ok-min font-weight-bold mx-auto mt-4" @click="updateTournament">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12-2 -->
    <div v-else-if="addTournamentScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToTournament">大会管理</a><i
            class="fas fa-angle-right form-control-color"></i> 大会新規登録</p>
        <div class="card p-5 text-first">
          <div class="card-body mx-auto">
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">大会名</label>
                <input v-model="tournament.tournamentName" class="form-control" placeholder=""
                       type="text">
                <div v-show="errors['tournamentName']" class="form-error">{{
                    errors['tournamentName']
                  }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label for="exampleInputPassword1">大会開始日</label>
                <input v-model="tournament.startAt" class="form-control half-width-input mt-1"
                       placeholder=""
                       type="date">
                <div v-show="errors['startAt']" class="form-error">{{ errors['startAt'] }}</div>
              </div>
              <div class="col-6">
                <label for="exampleInputPassword1">大会終了日</label>
                <input v-model="tournament.endAt" class="form-control half-width-input mt-1"
                       placeholder=""
                       type="date">
                <div v-show="errors['endAt']" class="form-error">{{ errors['endAt'] }}</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>開催都道府県</label>
                <select v-model="tournament.hostPrefectureId"
                        class="form-control filter-select-long form-select mt-1">
                  <option
                      v-for="(prefecture, prefectureId) in prefectures"
                      :key="prefectureId"
                      :value="prefecture.prefectureId"
                  >
                    {{ prefecture.prefectureName }}
                  </option>
                </select>
                <div v-show="errors['hostPrefectureId']" class="form-error">{{
                    errors['hostPrefectureId']
                  }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">主催団体</label>
                <input v-model="tournament.hostOrganization" class="form-control" placeholder=""
                       type="email">
                <div v-show="errors['hostOrganization']" class="form-error">{{
                    errors['hostOrganization']
                  }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">お問い合わせ電話番号</label>
                <input v-model="tournament.inquiryPhoneNo" class="form-control" placeholder=""
                       type="email">
                <div v-show="errors['inquiryPhoneNo']" class="form-error">{{
                    errors['inquiryPhoneNo']
                  }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">お問い合わせメール</label>
                <input v-model="tournament.inquiryEmail" class="form-control" placeholder=""
                       type="email">
                <div v-show="errors['inquiryEmail']" class="form-error">{{ errors['inquiryEmail'] }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label for="exampleInputPassword1">大会管理者<span class="text-888888">（複数名の場合はセミコロン「;」で区切って入力してください）</span></label>
                <input v-model="tournament.tournamentAdminEmail" class="form-control"
                       placeholder=""
                       type="email">
                <div v-show="errors['tournamentAdminEmail']" class="form-error">{{
                    errors['tournamentAdminEmail']
                  }}
                </div>
              </div>
            </div>
            <div class="form-check ml-2 mt-4 mx-auto">
              <input id="flexCheckDefault2" v-model="tournament.pastAnswersRequired"
                     class="form-check-input m-t-5"
                     type="checkbox" value="">
              <label class="form-check-label" for="flexCheckDefault2">過去14日間における回答をさせる
              </label>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <button class="btn cancel-min font-weight-bold" @click="backToTournament">キャンセル
                </button>
              </div>
              <div class="col-6">
                <button class="btn ok-min font-weight-bold" @click="createTournament">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12-6 -->
    <div v-else-if="batchRegistrationTournamentSuccessMsgScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToTournament">大会管理</a><i
            class="fas fa-angle-right form-control-color"></i> 大会一括新規登録</p>
        <div class="p-5 text-first">
          <div class="card-body mx-auto">
            <div class="d-flex justify-content-center flex-column">
              <div v-for="mes in tournamentUploadMessage">
                <p>{{ mes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12-5 -->
    <div v-else-if="batchRegistrationTournamentConfirmationScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToTournament">大会管理</a><i
            class="fas fa-angle-right form-control-color"></i> 大会一括新規登録</p>
        <div class="card p-5 text-first">
          <div class="card-body mx-auto">
            <div class="d-flex justify-content-center">
              <label>一括登録を実行します。よろしいですか？</label>
            </div>
            <div class="row mt-4 d-flex justify-content-between">
              <div class="col">
                <button class="btn cancel ml-2 font-weight-bold mx-auto mt-4"
                        @click="addBulkTournament">キャンセル
                </button>
              </div>
              <div class="col">
                <button class="btn disable ok font-weight-bold mx-auto mt-4"
                        @click="submitFile">登録
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12-3 -->
    <div v-else-if="addNewBulkTournamentScreen">
      <div class="container">
        <p><a class="text-decoration-underline cursor-pointer" @click="backToTournament">大会管理</a><i
            class="fas fa-angle-right form-control-color"></i>大会一括新規登録、</p>

        <div class="container text-center mt-5">
          <div>
            <button class="btn btn-primary height-44 width-350"><img
                src="../assets/images/document-text.png">
              <a class="text-decoration-none text-light" download
                 href="/download/batch_template_tournaments.xlsx">テンプレートファイルのダウンロード</a>
            </button>
          </div>
          <div class="input-group mt-5 width-720 mx-auto">
            <input class="form-control selected-file" placeholder="ファイル選択" type="text"/>
            <span class="input-group-btn"><span class="btn btn-file height-48"><span
                class="fa fa-upload"></span>
              <input ref="file" accept=".xls, .xlsx" type="file" @change="fileSelected"/></span></span>
          </div>
          <div class="mt-5 row button-container">
            <div class="col">
              <button class="btn cancel width-350" @click="backToTournament">キャンセル</button>
            </div>
            <div class="col">
              <button :disabled="Object.keys(this.file).length === 0 && this.file.constructor === Object"
                      class="btn ok width-350"
                      @click="batchRegistrationTournamentConfirmation">登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {app, gender, datatable, apiConfig, defaults} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import DatePicker from "../components/datepicker/DatePicker"
import {mapGetters} from "vuex"
import {AdminUploadResult} from "../assets/jsadminclient"
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";
import Helper from "jsadminclient/helper";

export default {
  name: "Tournaments",
  components: {Datatable, DatePicker, Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      response: {
        sort: 'status',
        order: 'desc',
        current_page: 1,
        per_page: datatable.default.perPage
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      keyword: "",
      deleteSuccess: false,
      deleteFailed: false,
      tournamentStatus: 0,
      tournamentStatuses: defaults.tournamentStatuses,
      file: {},
      reloadKey: 1,
      loading: true,
      addTournamentScreen: false,
      addNewBulkTournamentScreen: false,
      batchRegistrationTournamentConfirmationScreen: false,
      batchRegistrationTournamentSuccessMsgScreen: false,
      tournamentsListScreen: false,
      tournamentUploadMessage: [],
      columns: datatable.columns.tournaments,
      tournamentId: '',
      nextTournamentId: '',
      nextTournamentName: '',
      prevTournamentId: '',
      prevTournamentName: '',
      jtaTournament: {},
      tournament: {},
      prefectures: {},
      errors: []
    };
  },
  created() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler();
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }

      Object.assign(this.columns[0], {
        format: (tournamentStatus) => {
          return Helper.showTournamentStatus(tournamentStatus)
        },
      });

      Object.assign(this.columns[4], {
        format: (user_tournament_id) => {
          return Helper.showCTA();
        },
      });
      $(".tooltip").hide();
      this.getData();
    },
    updateData(dateRange) {
      this.dateRange = dateRange;
      this.getData();
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    search() {
      this.errors = []
      this.response.current_page = 1;
      this.getData();
    },
    addTournament() {
      this.$router.push({
        name: 'TournamentRegistration',
        params: {defaultResponse: this.response},
        query: this.createSearchQuery()
      })
    },
    addBulkTournament() {
      this.$router.push({
        name: 'TournamentBatchRegistration',
        params: {defaultResponse: this.response},
        query: this.createSearchQuery()
      })
    },
    backToTournament() {
      this.addTournamentScreen = false;
      this.addNewBulkTournamentScreen = false;
      this.batchRegistrationTournamentConfirmationScreen = false;
      this.batchRegistrationTournamentSuccessMsgScreen = false;
      this.tournamentsListScreen = false;
      this.$router.push({name: "Tournaments", query: this.createSearchQuery()});
    },
    batchRegistrationTournamentConfirmation() {
      this.batchRegistrationTournamentConfirmationScreen = true;
      this.batchRegistrationTournamentSuccessMsgScreen = false;
      this.addNewBulkTournamentScreen = false;
    },
    batchRegistrationTournamentSuccessMsg() {
      this.batchRegistrationTournamentSuccessMsgScreen = true;
      this.addNewBulkTournamentScreen = false;
      this.batchRegistrationTournamentConfirmationScreen = false;
    },
    tournamentsList() {
      this.tournamentsListScreen = true;
      this.batchRegistrationTournamentSuccessMsgScreen = false;
      this.addNewBulkTournamentScreen = false;
      this.batchRegistrationTournamentConfirmationScreen = false;
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    fileSelected() {
      this.file = this.$refs.file.files[0];
      $(".selected-file").val(this.$refs.file.files[0].name)
    },
    async submitFile() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsUploadPostAsync(
            this.file
        )
        if (result) {

          let res = AdminUploadResult.createFromJson(result)
          let mes = res.successCount + "件　登録しました。"
          if (res.updated) {
            this.tournamentUploadMessage.push(mes)
          }

          if (res.failureCount > 0) {
            mes = res.failureCount + "件　エラーになりました。"
            this.tournamentUploadMessage.push(mes)
          }

          res.failureReasons.forEach((item) => {
            this.tournamentUploadMessage.push(item)
          })

          this.batchRegistrationTournamentSuccessMsg()
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    formatResponse(response) {
      const result = response
      const paging = result.paging

      const current_page = paging.page
      const per_page = paging.pageParCount
      const from = per_page * (current_page - 1) + 1
      const last_page = paging.pageCount
      const to = per_page * current_page
      const total = paging.totalCount

      const sort = result.sort
      const order = result.order

      const data = response.items

      return {
        sort: sort,
        order: order,
        current_page: current_page,
        per_page: per_page ? per_page : datatable.default.perPage,
        from: from,
        to: to,
        last_page: last_page,
        total: total,
        data: data
      }
    },
    async getData() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        const result = await api.jtaTournamentsGetAsync(
            this.tournamentStatus,
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.keyword,
            this.response.sort,
            this.response.order,
            this.response.current_page,
            this.response.per_page
        );
        if (result.items) {
          this.loading = false;
          this.reloadKey += 1

          this.response = this.formatResponse(result)
          Common.updateCurrentPageQuery(this, this.createSearchQuery())
        }
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async createTournament() {
      try {
        this.errors = [];
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsPostAsync(
            this.tournament
        );
        this.backToTournament()
        this.getData()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async updateTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsTournamentIdPostAsync(
            this.tournamentId,
            this.jtaTournament
        );
        this.backToTournament()
        this.getData()
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        var result = await api.jtaTournamentsTournamentIdDeleteAsync(
            this.tournamentId,
        );
        Common.showToast("大会が削除されました。")
      } catch (e) {
        this.loading = false;
        this.deleteFailed = true
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    nextTournament() {
      this.tournamentId = this.nextTournamentId
      this.getTournament()
    },
    prevTournament() {
      this.tournamentId = this.prevTournamentId
      this.getTournament()
    },
    async getTournament() {
      try {
        const api = new AdminApi.JTATournamentApi(this.getApiConfig());
        this.jtaTournament = await api.jtaTournamentsTournamentIdGetAsync(
            this.tournamentId,
        );
        this.nextTournamentId = this.jtaTournament.nextTournamentId
        this.nextTournamentName = this.jtaTournament.nextTournamentName
        this.prevTournamentName = this.jtaTournament.prevTournamentName
        this.prevTournamentId = this.jtaTournament.prevTournamentId
      } catch (e) {
        this.jtaTournament = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getPrefecture() {
      try {
        const api = new AdminApi.SystemApi(this.getApiConfig());
        var result = await api.systemPrefecturesGetAsync();
        this.prefectures = result.items;
      } catch (e) {
        this.prefectures = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    cta(key, row) {
      this.$router.push({
        name: 'TournamentDetail',
        params: {
          tournamentId: row.tournamentId
        },
        query: this.createSearchQuery()
      })
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          {
            status: this.tournamentStatus,
            startAt: Common.dateFormatConvert(this.dateRange.startDate),
            endAt: Common.dateFormatConvert(this.dateRange.endDate),
            keyword: this.keyword,
            sort: this.response.sort,
            order: this.response.order,
            currentPage: this.response.current_page,
            perPage: this.response.per_page
          })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this, 'tournamentStatus', this.$route.query.status)
      if (this.$route.query.startAt && this.$route.query.endAt) {
        Common.restoreQuery(this.dateRange, 'startDate', this.$route.query.startAt)
        Common.restoreQuery(this.dateRange, 'endDate', this.$route.query.endAt)
        this.$refs.datePicker.setDateRange(this.dateRange, false)
      } else {
        this.$refs.datePicker.setLatest14DaysAsDefaultSelection(false)
      }

      Common.restoreQuery(this, 'keyword', this.$route.query.keyword)
      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
  },
};
</script>

<style lang="scss" scoped>

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 32px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 186px;
}

.date-range {
  width: 21%;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control:not(select) {
  //background: #F2ECF0;
  border: none;
}

::placeholder {
  color: #333333;
}

.fas {
  &.fa-plus {
    margin-top: 5px !important;
  }
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;
}

.ok {
  width: 414px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok:active {
  color: white;
  background-color: #c95d6c;
}

.cancel {
  width: 414px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel:active {
  color: #c95d6c;
  background-color: #ffffff;
}

.delete {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #333333;
}

.delete:active {
  color: white;
  background-color: #333333;
}

.ok-min {
  width: 200px;
  height: 44px;
  color: white;
  background-color: #c95d6c;
}

.ok-min:active {
  color: white;
  background-color: #c95d6c;
}

.cancel-min {
  width: 200px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #ffffff;
}

.cancel-min:active {
  color: #333333;
  background-color: #ffffff;
}

a {
  color: #333333;
}

.filter-select {
  //background-image: url('./src/assets/images/down.png');
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-search-form {
  border: 1px solid #dee2e6 !important;
  height: 32px !important;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.content {
  &.tournaments {
    .text-888888 {
      color: #888888;
    }

    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .filtering-column140 {
      min-width: 140px;
    }

    .filtering-column270 {
      min-width: 270px;
      max-width: 300px;
    }
  }
}

</style>

<style lang="scss">
.tournaments {
  table {
    tr {
      td {
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
</style>